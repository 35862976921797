import CommonUtils from '@/utils/common-utils';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class TemplatesMixin extends Vue {
  get searchText() {
    return this.$store.state.templates.searchText || '';
  }

  set searchText(value: string) {
    this.$store.commit('templates/setSearchText', value);
  }

  get selectedRows() {
    return this.$store.state.templates.selectedRows || [];
  }

  set selectedRows(value: Array<any>) {
    this.$store.commit('templates/setSelectedRows', value);
  }

  get hasSelectedRows() {
    return CommonUtils.isNotEmpty(this.selectedRows);
  }

  get editMode() {
    return this.$store.state.templates.editMode;
  }

  set editMode(value: boolean) {
    this.$store.commit('templates/setEditMode', value);
  }

  get isFiltered(): boolean {
    return this.$store.state.templates.isFiltered;
  }

  set isFiltered(v: boolean) {
    this.$store.commit('templates/setIsFiltered', v);
  }

  created() {
    this.editMode = false;
    this.searchText = '';
    this.selectedRows = [];
  }
}
