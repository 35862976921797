
























































































































































































































































































import BaseToolbar from '@/components/core/BaseToolbar.vue';
import TemplatesMixin from '@/mixins/templates-mixin';
import { Component } from 'vue-property-decorator';
import SearchFilter from '@/components/core/SearchFilter.vue';
import CommonUtils from '@/utils/common-utils';

@Component({
  mixins: [TemplatesMixin],
  components: {
    BaseToolbar,
    SearchFilter
  }
})
export default class TemplatesToolbar extends BaseToolbar {
  searchText!: string;
  editMode!: boolean;
  hasSelectedRows!: boolean;
  isFiltered!: boolean;
  itemFocused = '';

  $refs!: {
    searchFilter: SearchFilter
  }

  get localTemplatesComponent() {
    return this.currentPageInstance || {};
  }

  get mode() {
    return this.userMode;
  }

  get filterDialog() {
    return this.localTemplatesComponent.filterDialog || {};
  }

  get filterCount() {
    return this.filterDialog.filterCount || 0;
  }

  get filterOptions() {
    return this.localTemplatesComponent.filterOptions || {};
  }

  addTemplate() {
    return this.localTemplatesComponent.addTemplate();
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
  }

  deleteMultiplePopup() {
    return this.localTemplatesComponent.deleteMultiplePopup();
  }

  showFilters() {
    return this.localTemplatesComponent.showFilters();
  }

  applyFilters() {
    return this.localTemplatesComponent.applyFilters();
  }

  clearFilters() {
    return this.localTemplatesComponent.clearFilters();
  }

  focusMenu() {
    this.$nextTick(() => {
      new Promise(resolve => setTimeout(resolve, 100)).then(() => {
        try {
          (this.$refs as any).firstMenuItem.$el.querySelector('input').focus();
        } catch (e) {
          console.log(this.$t('focusFailed'));
        }
      });
    });
  }

  focusButton() {
    this.$refs.searchFilter.focusButton();
  }

  toggleFilter(filterSelections: Array<any>, item: any) {
    CommonUtils.arrayAddOrRemove(filterSelections, item);
    this.applyFilters();
  }

  get searchWidthGrow() {
    if (this.isFiltered) {
      return '40';
    } else {
      return '0';
    }
  }
}
