var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-toolbar", {
    attrs: { searchable: "", search: _vm.searchText },
    on: {
      "update:search": function($event) {
        _vm.searchText = $event
      }
    },
    scopedSlots: _vm._u(
      [
        {
          key: "toolbar-left",
          fn: function() {
            return [
              _c(
                "v-col",
                { staticClass: "px-0", attrs: { md: "2", sm: "3" } },
                [
                  _c("search-filter", {
                    ref: "searchFilter",
                    attrs: {
                      filterCount: _vm.filterCount,
                      isFiltered: _vm.isFiltered,
                      clearFilter: _vm.clearFilters,
                      searchText: _vm.searchText,
                      searchWidthGrow: _vm.searchWidthGrow,
                      mobileMode: false
                    },
                    on: {
                      "update:searchText": function($event) {
                        _vm.searchText = $event
                      },
                      "update:search-text": function($event) {
                        _vm.searchText = $event
                      },
                      focusMenu: _vm.focusMenu
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "filters-menu",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c("v-select", {
                                      ref: "firstMenuItem",
                                      staticClass: "ml-2",
                                      staticStyle: {
                                        "max-width": "190px",
                                        "min-width": "190px"
                                      },
                                      attrs: {
                                        label: _vm.$t("classLabel"),
                                        "display:inline-flex": "",
                                        "return-object": "",
                                        "hide-details": "auto",
                                        items: _vm.filterOptions.classList,
                                        "item-text": "className",
                                        "item-value": "classID",
                                        outlined: "",
                                        dense: ""
                                      },
                                      on: {
                                        change: _vm.applyFilters,
                                        keyup: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "escape",
                                              undefined,
                                              $event.key,
                                              undefined
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.focusButton.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.filterDialog.selectedClass,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.filterDialog,
                                            "selectedClass",
                                            $$v
                                          )
                                        },
                                        expression: "filterDialog.selectedClass"
                                      }
                                    }),
                                    _c("v-select", {
                                      staticClass: "ml-2",
                                      staticStyle: {
                                        "max-width": "190px",
                                        "min-width": "190px"
                                      },
                                      attrs: {
                                        label: _vm.$t("dayLabel"),
                                        "display:inline-flex": "",
                                        "return-object": "",
                                        "hide-details": "auto",
                                        items: _vm.filterOptions.dayList,
                                        "item-text": "title",
                                        outlined: "",
                                        dense: ""
                                      },
                                      on: {
                                        change: _vm.applyFilters,
                                        keyup: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "escape",
                                              undefined,
                                              $event.key,
                                              undefined
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.focusButton.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.filterDialog.selectedDay,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.filterDialog,
                                            "selectedDay",
                                            $$v
                                          )
                                        },
                                        expression: "filterDialog.selectedDay"
                                      }
                                    }),
                                    _c("v-select", {
                                      staticClass: "ml-2",
                                      staticStyle: {
                                        "max-width": "190px",
                                        "min-width": "190px"
                                      },
                                      attrs: {
                                        label: _vm.$t("sectionLabel"),
                                        "display:inline-flex": "",
                                        "return-object": "",
                                        "hide-details": "auto",
                                        items: _vm.filterOptions.sectionList,
                                        "item-text": "title",
                                        outlined: "",
                                        dense: ""
                                      },
                                      on: {
                                        change: _vm.applyFilters,
                                        keyup: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "escape",
                                              undefined,
                                              $event.key,
                                              undefined
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.focusButton.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.filterDialog.selectedSection,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.filterDialog,
                                            "selectedSection",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "filterDialog.selectedSection"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "mt-6",
                                    attrs: { outlined: "" }
                                  },
                                  [
                                    _c(
                                      "v-card-text",
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "title-on-border" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("templatesFilterLabel")
                                              )
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-chip-group",
                                          {
                                            attrs: {
                                              "active-class":
                                                "accent3--text font-weight-bold",
                                              column: "",
                                              multiple: ""
                                            },
                                            on: { update: _vm.applyFilters },
                                            model: {
                                              value:
                                                _vm.filterDialog
                                                  .templatesFilterChips,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.filterDialog,
                                                  "templatesFilterChips",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "filterDialog.templatesFilterChips"
                                            }
                                          },
                                          [
                                            _c(
                                              "pb-chip",
                                              {
                                                staticStyle: {
                                                  display: "inline"
                                                },
                                                attrs: {
                                                  filter: "",
                                                  outlined: ""
                                                },
                                                on: {
                                                  keyup: [
                                                    function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "escape",
                                                          undefined,
                                                          $event.key,
                                                          undefined
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.focusButton.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                    function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.toggleFilter(
                                                        _vm.filterDialog
                                                          .templatesFilterChips,
                                                        0
                                                      )
                                                    }
                                                  ]
                                                }
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("oldTemplateLabel")
                                                    )
                                                  )
                                                ])
                                              ]
                                            ),
                                            _c(
                                              "pb-chip",
                                              {
                                                staticStyle: {
                                                  display: "inline"
                                                },
                                                attrs: {
                                                  filter: "",
                                                  outlined: ""
                                                },
                                                on: {
                                                  keyup: [
                                                    function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "escape",
                                                          undefined,
                                                          $event.key,
                                                          undefined
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.focusButton.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                    function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.toggleFilter(
                                                        _vm.filterDialog
                                                          .templatesFilterChips,
                                                        1
                                                      )
                                                    }
                                                  ]
                                                }
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "hiddenTemplateLabel"
                                                      )
                                                    )
                                                  )
                                                ])
                                              ]
                                            ),
                                            _c(
                                              "pb-chip",
                                              {
                                                staticStyle: {
                                                  display: "inline"
                                                },
                                                attrs: {
                                                  filter: "",
                                                  outlined: ""
                                                },
                                                on: {
                                                  keyup: [
                                                    function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "escape",
                                                          undefined,
                                                          $event.key,
                                                          undefined
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.focusButton.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                    function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.toggleFilter(
                                                        _vm.filterDialog
                                                          .templatesFilterChips,
                                                        2
                                                      )
                                                    }
                                                  ]
                                                }
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "hideInactiveTemplatesLabel"
                                                      )
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm.filterDialog.showOld
                                          ? _c("v-select", {
                                              staticClass: "mt-2",
                                              attrs: {
                                                label: _vm.$t(
                                                  "schoolYearLabel"
                                                ),
                                                "width:64px": "",
                                                "display:inline-flex": "",
                                                "return-object": "",
                                                "hide-details": "auto",
                                                items:
                                                  _vm.filterOptions.yearList,
                                                "item-text": "text",
                                                outlined: "",
                                                dense: ""
                                              },
                                              on: {
                                                change: _vm.applyFilters,
                                                keyup: function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "escape",
                                                      undefined,
                                                      $event.key,
                                                      undefined
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.focusButton.apply(
                                                    null,
                                                    arguments
                                                  )
                                                }
                                              },
                                              model: {
                                                value:
                                                  _vm.filterDialog.selectedYear,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.filterDialog,
                                                    "selectedYear",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "filterDialog.selectedYear"
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ]
          },
          proxy: true
        },
        {
          key: "toolbar-right",
          fn: function() {
            return [
              !_vm.editMode
                ? _c(
                    "pb-btn",
                    {
                      attrs: {
                        color: "primary",
                        label:
                          _vm.$t("addLabel") + " " + _vm.$t("templateLabel"),
                        elevation: "0",
                        text: "",
                        outlined: ""
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.addTemplate.apply(null, arguments)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("fal fa-plus")])],
                    1
                  )
                : [
                    _vm.hasSelectedRows
                      ? _c(
                          "pb-btn",
                          {
                            staticClass: "ml-2 my-1",
                            attrs: {
                              color: "error",
                              label: _vm.$t("deleteLabel"),
                              text: "",
                              outlined: ""
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.deleteMultiplePopup.apply(
                                  null,
                                  arguments
                                )
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("fal fa-trash-alt")])],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "pb-btn",
                      {
                        staticClass: "ml-2 my-1",
                        attrs: {
                          color: "primary",
                          label: _vm.$t("doneLabel"),
                          text: "",
                          outlined: ""
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.toggleEditMode.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c("v-icon", { staticClass: "done-icon" }, [
                          _vm._v("fal fa-pen-slash")
                        ])
                      ],
                      1
                    )
                  ],
              !_vm.editMode
                ? _c(
                    "v-menu",
                    {
                      attrs: {
                        bottom: "",
                        "offset-y": "",
                        "close-on-click": ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var onMenu = ref.on
                              return [
                                _c(
                                  "pb-btn",
                                  _vm._g(
                                    {
                                      staticClass: "ml-2 my-1",
                                      attrs: {
                                        color: "primary",
                                        label: _vm.$t("actionsLabel"),
                                        text: "",
                                        outlined: ""
                                      }
                                    },
                                    Object.assign({}, onMenu)
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("fal fa-ellipsis-stroke-vertical")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        648777904
                      )
                    },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            { on: { click: _vm.toggleEditMode } },
                            [
                              _c(
                                "v-btn",
                                { attrs: { text: "", color: "primary" } },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("fal fa-pen-to-square")
                                  ]),
                                  _c("span", { staticClass: "ml-1" }, [
                                    _vm._v(_vm._s(_vm.$t("manageLabel")))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          },
          proxy: true
        },
        _vm.$currentUser.isTeacher
          ? {
              key: "mobile-search-filters",
              fn: function() {
                return [
                  _c("search-filter", {
                    ref: "searchFilter",
                    attrs: {
                      filterCount: _vm.filterCount,
                      isFiltered: _vm.isFiltered,
                      clearFilter: _vm.clearFilters,
                      mobileMode: true
                    },
                    on: { focusMenu: _vm.focusMenu },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "filters-menu",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("v-select", {
                                        ref: "firstMenuItem",
                                        staticClass: "ml-2 mb-2",
                                        staticStyle: {
                                          "max-width": "190px",
                                          "min-width": "190px"
                                        },
                                        attrs: {
                                          label: _vm.$t("classLabel"),
                                          "display:inline-flex": "",
                                          "return-object": "",
                                          "hide-details": "auto",
                                          items: _vm.filterOptions.classList,
                                          "item-text": "className",
                                          "item-value": "classID",
                                          outlined: "",
                                          dense: ""
                                        },
                                        on: {
                                          change: _vm.applyFilters,
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "escape",
                                                undefined,
                                                $event.key,
                                                undefined
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.focusButton.apply(
                                              null,
                                              arguments
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.filterDialog.selectedClass,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filterDialog,
                                              "selectedClass",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "filterDialog.selectedClass"
                                        }
                                      }),
                                      _c("v-select", {
                                        staticClass: "ml-2 mb-2",
                                        staticStyle: {
                                          "max-width": "190px",
                                          "min-width": "190px"
                                        },
                                        attrs: {
                                          label: _vm.$t("dayLabel"),
                                          "display:inline-flex": "",
                                          "return-object": "",
                                          "hide-details": "auto",
                                          items: _vm.filterOptions.dayList,
                                          "item-text": "title",
                                          outlined: "",
                                          dense: ""
                                        },
                                        on: {
                                          change: _vm.applyFilters,
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "escape",
                                                undefined,
                                                $event.key,
                                                undefined
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.focusButton.apply(
                                              null,
                                              arguments
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.filterDialog.selectedDay,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filterDialog,
                                              "selectedDay",
                                              $$v
                                            )
                                          },
                                          expression: "filterDialog.selectedDay"
                                        }
                                      }),
                                      _c("v-select", {
                                        staticClass: "ml-2",
                                        staticStyle: {
                                          "max-width": "190px",
                                          "min-width": "190px"
                                        },
                                        attrs: {
                                          label: _vm.$t("sectionLabel"),
                                          "display:inline-flex": "",
                                          "return-object": "",
                                          "hide-details": "auto",
                                          items: _vm.filterOptions.sectionList,
                                          "item-text": "title",
                                          outlined: "",
                                          dense: ""
                                        },
                                        on: {
                                          change: _vm.applyFilters,
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "escape",
                                                undefined,
                                                $event.key,
                                                undefined
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.focusButton.apply(
                                              null,
                                              arguments
                                            )
                                          }
                                        },
                                        model: {
                                          value:
                                            _vm.filterDialog.selectedSection,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filterDialog,
                                              "selectedSection",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "filterDialog.selectedSection"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "mt-6",
                                      attrs: { outlined: "" }
                                    },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "title-on-border" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("templatesFilterLabel")
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-chip-group",
                                            {
                                              attrs: {
                                                "active-class":
                                                  "accent3--text font-weight-bold",
                                                column: "",
                                                multiple: ""
                                              },
                                              on: { change: _vm.applyFilters },
                                              model: {
                                                value:
                                                  _vm.filterDialog
                                                    .templatesFilterChips,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.filterDialog,
                                                    "templatesFilterChips",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "filterDialog.templatesFilterChips"
                                              }
                                            },
                                            [
                                              _c(
                                                "pb-chip",
                                                {
                                                  staticStyle: {
                                                    display: "inline"
                                                  },
                                                  attrs: {
                                                    filter: "",
                                                    outlined: ""
                                                  },
                                                  on: {
                                                    keyup: [
                                                      function($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "escape",
                                                            undefined,
                                                            $event.key,
                                                            undefined
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        return _vm.focusButton.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                      function($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        return _vm.toggleFilter(
                                                          _vm.filterDialog
                                                            .templatesFilterChips,
                                                          0
                                                        )
                                                      }
                                                    ]
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "oldTemplateLabel"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "pb-chip",
                                                {
                                                  staticStyle: {
                                                    display: "inline"
                                                  },
                                                  attrs: {
                                                    filter: "",
                                                    outlined: ""
                                                  },
                                                  on: {
                                                    keyup: [
                                                      function($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "escape",
                                                            undefined,
                                                            $event.key,
                                                            undefined
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        return _vm.focusButton.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                      function($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        return _vm.toggleFilter(
                                                          _vm.filterDialog
                                                            .templatesFilterChips,
                                                          1
                                                        )
                                                      }
                                                    ]
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "hiddenTemplateLabel"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "pb-chip",
                                                {
                                                  staticStyle: {
                                                    display: "inline"
                                                  },
                                                  attrs: {
                                                    filter: "",
                                                    outlined: ""
                                                  },
                                                  on: {
                                                    keyup: [
                                                      function($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "escape",
                                                            undefined,
                                                            $event.key,
                                                            undefined
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        return _vm.focusButton.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                      function($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        return _vm.toggleFilter(
                                                          _vm.filterDialog
                                                            .templatesFilterChips,
                                                          2
                                                        )
                                                      }
                                                    ]
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "hideInactiveTemplatesLabel"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm.filterDialog.showOld
                                            ? _c("v-select", {
                                                staticClass: "mt-2",
                                                attrs: {
                                                  label: _vm.$t(
                                                    "schoolYearLabel"
                                                  ),
                                                  "width:64px": "",
                                                  "display:inline-flex": "",
                                                  "return-object": "",
                                                  "hide-details": "auto",
                                                  items:
                                                    _vm.filterOptions.yearList,
                                                  "item-text": "text",
                                                  outlined: "",
                                                  dense: ""
                                                },
                                                on: {
                                                  change: _vm.applyFilters,
                                                  keyup: function($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "escape",
                                                        undefined,
                                                        $event.key,
                                                        undefined
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.focusButton.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.filterDialog
                                                      .selectedYear,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.filterDialog,
                                                      "selectedYear",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "filterDialog.selectedYear"
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2378302842
                    )
                  })
                ]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }